import React from "react";
import { HeroProps } from "../types/appTypes";

const Hero: React.FC<HeroProps> = ({ title, text }) => {
  return (
    <header className="px-6 w-full flex flex-grow justify-center items-center py-10">
      <div className="text-center container">
        <h1 className="mb-6 -mt-36 text-default-text font-normal text-4xl lg:text-6xl">
          {title}
        </h1>
        <p className="font-thin text-xl md:text-2xl">{text}</p>
      </div>
    </header>
  );
};

export default Hero;
