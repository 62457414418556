import JonathanPhoto from '../../assets/img/Jonathan.jpeg'
import MaxiPhoto from '../../assets/img/Maximiliano.jpeg'
import HugoPhoto from '../../assets/img/Hugo.png'
import BarbaraPhoto from '../../assets/img/Barbara.png'

export const teamMembers = [
  {
    name: "Hugo",
    photo: HugoPhoto,
    position: "CTO",
    text: "Meet Hugo, our brilliant Chief Technology Officer who is fluent in the language of code and possesses an unparalleled mastery of technology. While Hugo primarily communicates through the intricate dance of algorithms and programming languages, his exceptional skill extends beyond the realm of tech. With a unique ability to break down complex concepts for non-tech audiences, Hugo plays a pivotal role in bridging the gap between innovation and understanding.",
    flag: "🇲🇽",
  },
  {
    name: "Barbara Baro",
    photo: BarbaraPhoto,
    position: "CEO",
    text: "A trailblazer in the travel industry, Barbara Baro, our visionary CEO, seamlessly combines seasoned expertise with a dynamic spirit. A true pioneer, Barbara embraced the digital nomad lifestyle long before it became a trend, bringing a unique blend of innovation and experience to the helm of our company. Her leadership propels us forward, shaping a future where travel meets technology in the most extraordinary ways.",
    flag: "🇮🇹",
  },
  {
    name: "Jonathan P.",
    photo: JonathanPhoto,
    position: "PMO",
    text: "Jonathan delivers success across diverse industries (IT, customer service, marketing, you name it!) with his extensive project management skills and collaborative spirit. He tackles projects head-on, adapting to any environment with both classic and agile approaches. Driven by a passion for achieving goals and exceeding expectations, Jonathan thrives in dynamic settings, constantly seeking ways to learn and grow both himself and his team.",
    flag: "🇲🇽",
  },
  {
    name: "Daria",
    photo:
      "https://avatars.githubusercontent.com/u/73955564?s=400&u=3ba82d92165f646f28c9be7ba5eed77ccbd63b7a&v=4",
    position: "Developer",
    text: "Daria is a vital contributor to our team, focusing on crafting and refining web applications that align with our project's objectives. With a solid grasp of web development languages and frameworks, she effectively translates ideas into functional and aesthetically pleasing web solutions. Daria works collaboratively with the team to ensure that our websites are user-friendly, responsive, and up-to-date with the latest web standards.",
    flag: "🇺🇦",
  },
];
