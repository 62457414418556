// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCard from "./swiperCard";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, EffectCoverflow } from "swiper/modules";
import { TeamSectionProps } from "../types/appTypes";
import { useTranslation } from "react-i18next";

const SwiperComponent: React.FC<TeamSectionProps> = ({ teamMembers }) => {
  const {t} = useTranslation();

  return (
    <div className="relative flex justify-center items-center">
      <Swiper
        effect={"coverflow"}
        slidesPerView={3}
        spaceBetween={20}
        loop={true}
        coverflowEffect={{
          rotate: 0,
          stretch: -30,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{
          clickable: true,
          el: ".custom-swiper-pagination",
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 5,
            coverflowEffect: {
              rotate: 0,
              stretch: 0,
              depth: 0,
              modifier: 1,
              slideShadows: false,
            },
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
            coverflowEffect: {
              rotate: 0,
              stretch: 0,
              depth: 0,
              modifier: 1,
              slideShadows: false,
            },
          },
          1020: {
            slidesPerView: 3,
            spaceBetween: 20,
            coverflowEffect: {
              rotate: 0,
              stretch: -30,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            },
          },
        }}
      >
        {teamMembers.map((member, index) => (
          <SwiperSlide key={index}>
            <SwiperCard
              name={member.name}
              photo={member.photo}
              position={member.position}
              text={t(member.text)}
              flag={member.flag}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="custom-swiper-pagination"></div>
      <div className="swiper-button-next custom-button"></div>
      <div className="swiper-button-prev custom-button"></div>
    </div>
  );
};

export default SwiperComponent;
