import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "400px",
  height: "400px",
};

const containerStyleMob = {
  width: "250px",
  height: "250px",
};

const center = {
  lat: 28.094329702959328,
  lng: -16.724009289560037,
};

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

function Map() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  return isLoaded ? (
    <>
      <div className="block mx-auto sm:hidden">
        <GoogleMap
          mapContainerStyle={containerStyleMob}
          center={center}
          zoom={10}
        >
          <Marker position={center} />
        </GoogleMap>
      </div>
      <div className="hidden sm:block mx-auto">
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
          <Marker position={center} />
        </GoogleMap>
      </div>
    </>
  ) : (
    <></>
  );
}

export default React.memo(Map);
