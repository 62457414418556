export const mapPoints = [
  {
    pointPosition:
      "right-[23%] bottom-[40%] xs:right-[23.5%] sm:right-[24%] md:right-[23%] lg:right-[24%]",
    pointColor: "blue-location",
    pointText: "Thailand",
    pointBgColor: "blue-location-bg",
  },
  {
    pointPosition:
      "top-[30%] left-[49.5%] xxs:top-[31%] xs:left-[50%] xs:top-[33%] md:top-[32%] lg:left-[50.5%] lg:top-[34%]",
    pointColor: "yellow-location",
    pointText: "Italy",
    pointBgColor: "yellow-location-bg",
  },
  {
    pointPosition:
      "top-[30%] left-[48.5%] xxs:top-[31%] xs:left-[49%] xs:top-[33%] md:top-[32%] lg:left-[49.5%] lg:top-[34%]",
    pointColor: "red-location",
    pointText: "Italy",
    pointBgColor: "red-location-bg",
  },
  {
    pointPosition:
      "top-[27%] left-[45%] xs:top-[28%] xs:left-[46%] md:top-[29%] lg:top-[30%]",
    pointColor: "yellow-location",
    pointText: "France",
    pointBgColor: "yellow-location-bg",
  },
  {
    pointPosition:
      "top-[28%] left-[47%] xs:top-[30%] xs:left-[48%] md:top-[30%] lg:top-[31%]",
    pointColor: "yellow-location",
    pointText: "France",
    pointBgColor: "yellow-location-bg",
  },
  {
    pointPosition:
      "top-[32%] left-[44%] xs:top-[34.5%] xs:left-[45.5%] sm:top-[36%] sm:left-[45%] md:top-[34%] lg:top-[35%]",
    pointColor: "yellow-location",
    pointText: "Spain",
    pointBgColor: "yellow-location-bg",
  },
  {
    pointPosition:
      "top-[23%] left-[47%] xs:top-[25%] xs:left-[47.5%] sm:top-[26%] sm:left-[48%] md:top-[24%] md:left-[47.5%] lg:top-[25.4%]",
    pointColor: "yellow-location",
    pointText: "Amsterdam",
    pointBgColor: "yellow-location-bg",
  },
  {
    pointPosition:
      "top-[40%] left-[53%] xs:top-[43%] sm:left-[54%] md:top-[41%] md:left-[53%] lg:top-[43%] lg:left-[54%]",
    pointColor: "blue-location",
    pointText: "Egypt",
    pointBgColor: "blue-location-bg",
  },
  {
    pointPosition: "top-[60%] left-[56%] xxs:left-[57%] xs:top-[63%]",
    pointColor: "blue-location",
    pointText: "Zanzibar",
    pointBgColor: "blue-location-bg",
  },
  {
    pointPosition:
      "top-[34%] left-[54.5%] xxs:top-[35%] xxs:left-[55%] xs:top-[36%] xs:left-[55.2%] sm:top-[37%] sm:left-[55.5%] md:top-[35.5%] md:left-[55%] lg:top-[37%] lg:left-[55.5%]",
    pointColor: "blue-location",
    pointText: "Cyprus",
    pointBgColor: "blue-location-bg",
  },
  {
    pointPosition:
      "top-[45%] left-[24%] xxs:top-[46%] xs:top-[48%] xs:left-[24.5%] md:top-[47%] lg:top-[48.5%] lg:left-[24.7%]",
    pointColor: "turquoise-location",
    pointText: "Jamaica",
    pointBgColor: "turquoise-location-bg",
  },
  {
    pointPosition:
      "w-36 top-[44.4%] left-[26.5%] xxs:top-[45%] xs:top-[46.5%] xs:left-[27.3%] sm:top-[47.5%] md:top-[46%] lg:top-[47%]",
    pointColor: "blue-location",
    pointText: "Saint Kitts and Nevis",
    pointBgColor: "blue-location-bg",
  },
  {
    pointPosition:
      "w-36 top-[45.4%] left-[27.5%] xxs:top-[46%] xs:top-[47.5%] xs:left-[28.3%] sm:top-[48.5%] md:top-[47%] lg:top-[48%]",
    pointColor: "turquoise-location",
    pointText: "Saint Kitts and Nevis",
    pointBgColor: "turquoise-location-bg",
  },
  {
    pointPosition:
      "w-32 top-[43%] left-[25%] xs:top-[45%] xs:left-[26%] md:top-[44%] lg:top-[46%] lg:left-[25.7%]",
    pointColor: "blue-location",
    pointText: "Balmoral Island",
    pointBgColor: "blue-location-bg",
  },
  {
    pointPosition:
      "w-32 top-[43%] left-[23%] xs:top-[45%] xs:left-[25%] md:top-[44%] lg:top-[45%] lg:left-[24.7%]",
    pointColor: "turquoise-location",
    pointText: "Balmoral Island",
    pointBgColor: "turquoise-location-bg",
  },
  {
    pointPosition:
      "top-[22%] left-[45%] xs:top-[24%] xs:left-[45.5%] sm:top-[25%] sm:left-[46%] md:top-[23%] md:left-[46%] lg:top-[25.4%]",
    pointColor: "blue-location",
    pointText: "UK",
    pointBgColor: "blue-location-bg",
  },
  {
    pointPosition:
      "top-[22%] left-[45.5%] xs:top-[24%] xs:left-[46.5%] sm:top-[25%] sm:left-[45%] md:top-[23%] md:left-[45%] lg:top-[25.4%]",
    pointColor: "yellow-location",
    pointText: "UK",
    pointBgColor: "yellow-location-bg",
  },
  {
    pointPosition:
      "top-[35%] left-[39.5%] xxs:left-[42%] xs:top-[37%] xs:left-[41.5%] sm:top-[39%] sm:left-[42%] lg:top-[41%]",
    pointColor: "red-location",
    pointText: "Tenerife",
    pointBgColor: "red-location-bg",
  },
  {
    pointPosition:
      "top-[36%] left-[41%] xxs:left-[40%] xs:top-[37%] xs:left-[40.5%] sm:top-[39%] sm:left-[41%] lg:top-[41%]",
    pointColor: "yellow-location",
    pointText: "Tenerife",
    pointBgColor: "yellow-location-bg",
  },
  {
    pointPosition:
      "top-[37%] left-[40%] xxs:left-[41%] xxs:top-[38%] sm:top-[40%] sm:left-[41.5%] lg:top-[41.5%]",
    pointColor: "turquoise-location",
    pointText: "Tenerife",
    pointBgColor: "turquoise-location-bg",
  },
  {
    pointPosition:
      "top-[45%] left-[20%] xxs:top-[45.5%] xxs:left-[20.5%] xs:top-[46.5%] xs:left-[21%] sm:top-[47.5%] sm:left-[21%] md:top-[46.5%] md:left-[20.5%] lg:top-[47%] lg:left-[21%]",
    pointColor: "red-location",
    pointText: "Mexican Riviera Maya",
    pointBgColor: "red-location-bg",
  },
  {
    pointPosition:
      "top-[45%] left-[21%] xxs:top-[45.6%] xxs:left-[21.5%] xs:top-[46.8%] xs:left-[22%] sm:top-[48%] sm:left-[22%] md:top-[47%] md:left-[21.5%] lg:top-[48%] lg:left-[22%]",
    pointColor: "turquoise-location",
    pointText: "Mexican Riviera Maya",
    pointBgColor: "turquoise-location-bg",
  },
];
