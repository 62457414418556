import React from "react";
import { HeroProps } from "../types/appTypes";

const HeroGetInTouch: React.FC<HeroProps> = ({ title, text }) => {
  return (
    <header className="px-6 w-full flex justify-center">
      <div className="text-center py-20  max-w-2xl">
        <h1 className="uppercase text-black mb-6">{title}</h1>
        <p className="text-black">{text}</p>
      </div>
    </header>
  );
};

export default HeroGetInTouch;
