import { useState, useEffect, useMemo } from "react";
import { WeatherData } from "../../types/appTypes";

const useWeather = () => {
  const [weather, setWeather] = useState<WeatherData | null>(null);
  const WEATHER_API = process.env.REACT_APP_WEATHER_API || "";

  useEffect(() => {
    const fetchData = async () => {
      const url = `https://api.openweathermap.org/data/2.5/weather?lat=28.469648&lon=-16.2540884&appid=${WEATHER_API}&units=metric`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        setWeather(data);
      } catch (error) {
        console.error("Error fetching weather data: ", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const weatherData = useMemo(() => {
    if (weather) {
      const temp = weather.main.temp;
      let bgColorLight = "var(--color-default-light)";
      let bgColorDark = "var(--color-default-dark)";

      if (temp <= 18) {
        bgColorLight = "var(--color-cold-light)";
        bgColorDark = "var(--color-cold-dark)";
      } else if (
        temp > 18 &&
        temp < 35 &&
        weather.weather[0].main === "Clear"
      ) {
        bgColorLight = "var(--color-clear-light)";
        bgColorDark = "var(--color-clear-dark)";
      } else if (
        temp > 18 &&
        temp < 35 &&
        weather.weather[0].main === "Clouds"
      ) {
        bgColorLight = "var(--color-cloudy-light)";
        bgColorDark = "var(--color-cloudy-dark)";
      } else if (temp > 18 && temp < 35 && weather.weather[0].main === "Rain") {
        bgColorLight = "var(--color-rainy-light)";
        bgColorDark = "var(--color-rainy-dark)";
      } else if (weather.main.temp >= 35) {
        bgColorLight = "var(--color-hot-light)";
        bgColorDark = "var(--color-hot-dark)";
      }
      return { bgColorLight, bgColorDark };
    }
    return { bgColorLight: "var(--color-default)" };
  }, [weather]);

  return {
    weather,
    backgroundColorLight: weatherData.bgColorLight,
    backgroundColorDark: weatherData.bgColorDark,
  };
};

export default useWeather;
