import React from "react";
import agileImg from "./../assets/img/agile.png";
import { useTranslation } from "react-i18next";

const AgileSection = () => {
  const {t} = useTranslation();

  return (
    <section className="bg-services-bg-color">
      <div className="container py-20 md:px-10 px-4">
        <div className="flex items-center flex-col gap-10 sm:gap-0 sm:flex-row ">
          <div className="sm:w-2/3 w-full 2xl:w-1/2">
            <h1 className="text-services-text-color mb-5">
              {t("KODE's Agile Approach")}
            </h1>
            <p className="font-thin text-lg leading-tight max-w-sm lg:max-w-md">
          {t("Our iterative and collaborative methodology ensures adaptability, transparency, and accelerated delivery, creating software solutions that evolve seamlessly with your needs.")}
            </p>
          </div>
          <div className="sm:w-1/3 w-full 2xl:w-1/2">
            <img
              src={agileImg}
              alt="agile"
              className="object-cover object-center w-full"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AgileSection;
