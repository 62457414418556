export const categories = [
  {
    text: "Clients",
    borderColor: "border-b-[#FFCA01]",
    iconColor: "yellow-location",
  },
  {
    text: "Project Management",
    borderColor: "border-b-[#2AB6CA]",
    iconColor: "turquoise-location",
  },
  {
    text: "Products",
    borderColor: "border-b-[#FF0000]",
    iconColor: "red-location",
  },
  {
    text: "Projects",
    borderColor: "border-b-[#100DB1]",
    iconColor: "blue-location",
  },
];
