import { useTranslation } from "react-i18next";
import AppNav from "../components/appNav";
import ContactForm from "../components/contactForm";
import ContactsSection from "../components/contactsSection";
import Footer from "../components/footer";
import HeroGetInTouch from "../components/heroGetInTouch";

const GetInTouchPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col kode-bg get-bg">
        <AppNav textColor="text-black-text" />
        <div className="lg:py-32 py-20">
          <HeroGetInTouch
            title={t("Get in Touch")}
            text={t(
              "We take joy in presenting tailored solutions that perfectly align with the unique needs of your business, ensuring an optimal fit for your growth and success."
            )}
          />
        </div>
      </div>
      <ContactsSection />
      <ContactForm />
      <Footer />
    </>
  );
};

export default GetInTouchPage;
