import React from "react";
import Footer from "../components/footer";
import AppNav from "../components/appNav";
import Hero from "../components/hero";
import OurFootprintSection from "../components/ourFootprintSection";
import { useTranslation } from "react-i18next";

const OurFootPrintPage = () => {
  const {t} = useTranslation();

  return (
    <>
      <div className="flex flex-col min-h-screen kode-bg our-footprint-bg">
        <AppNav textColor="text-default-text" />
        <Hero
          title={t("Our Geographic Footprint")}
          text={t("Mapping Success Stories Around the Globe")}
        />
      </div>
      <OurFootprintSection />
      <Footer />
    </>
  );
};

export default OurFootPrintPage;
