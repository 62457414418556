import React from "react";
import { HeroProps } from "../types/appTypes";
import computerImage from "./../assets/img/services-hero.png";

const HeroServices: React.FC<HeroProps> = ({ title, text }) => {
  return (
    <header className="flex flex-grow items-center">
      <div className="flex flex-col lg:gap-8 sm:flex-row">
        <div className="sm:w-1/2 w-full">
          <img
            src={computerImage}
            alt="computer"
            className="object-cover object-center w-full"
          />
        </div>
        <div className="sm:w-1/2 w-full max-w-lg xl:max-w-xl px-6 sm:pr-16 pb-10 sm:pb-0 mt-10 sm:mt-0 md:mt-10 lg:mt-20">
          <h1 className="mb-10 text-services-text-color">{title}</h1>
          <p className="text-services-text-color font-thin text-lg leading-tight">
            {text}
          </p>
        </div>
      </div>
    </header>
  );
};

export default HeroServices;
