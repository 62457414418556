import React from "react";
import { useTranslation } from "react-i18next";

const KitDigitalInfoSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section>
      <div className="container py-20 px-4 md:px-10">
        <div className="">
          <h2 className="mb-5 text-center">
            {t("Qué es el Kit Digital y Cómo Solicitarlo")}
          </h2>
          <h4 className="mb-8 text-dark-text font-semibold text-center">
            {t("Descripción y Condiciones del Programa")}
          </h4>
          <p className="text-services-text-color font-thin md:text-lg">
            {t(
              "El Programa Kit Digital ofrece subvenciones para apoyar la transformación digital de diversas entidades, incluidas pymes, autónomos, ONG, asociaciones, comunidades y sociedades civiles. Esta ayuda te permite crear o mejorar una página web, establecer una tienda online o mejorar tu presencia en internet. Además, incluye la gestión de redes sociales y otras herramientas digitales esenciales."
            )}
          </p>
        </div>
      </div>
    </section>
  );
};

export default KitDigitalInfoSection;
