import React from "react";
import processImg from "./../assets/img/process.png";
import useWeather from "../utils/hooks/useWeather";
import { useTranslation } from "react-i18next";

const ExcellenceSection = () => {
  const { t } = useTranslation();
  const { backgroundColorLight } = useWeather();
  
  return (
    <section style={{ backgroundColor: backgroundColorLight }}>
      <div className="container py-20 md:px-10 px-4">
        <div className="flex items-center flex-col gap-10 md:gap-5 md:flex-row ">
          <div className="md:w-1/2 w-full">
            <h1 className="mb-5">
              {t("Empowering Innovation, Delivering Excellence")}
            </h1>
            <p className="font-thin md:text-justify text-black-text text-lg max-w-sm lg:max-w-md">
             {t("At our core, we embrace agile-scrum methodology to architect software products. Our approach involves planning the overall scope in sprints, maintaining governance on a large scale. It's a proven formula for success in continuous delivery.")}
            </p>
          </div>
          <div className="md:w-1/2 w-full">
            <img
              src={processImg}
              alt="agile"
              className="object-cover object-center w-full"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExcellenceSection;
