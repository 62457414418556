import React from "react";
import useWeather from "../utils/hooks/useWeather";
import { NavProps } from "../types/appTypes";

const WeatherWidget: React.FC<NavProps> = ({ textColor }) => {
  const { weather } = useWeather();

  const getWeatherIconUrl = (iconCode: string) => {
    return `http://openweathermap.org/img/wn/${iconCode}.png`;
  };

  if (!weather) {
    return <div></div>;
  }

  return (
    <div className="rounded-md px-2">
      <div className="flex">
        <div className="flex items-center">
          <div className="flex flex-col text-center">
            <p className={`font-semibold text-xs ${textColor}`}>Tenerife</p>
            <p className={`font-thin -mt-1 text-xs ${textColor}`}>Adeje</p>
          </div>

          <img
            className="w-10"
            src={getWeatherIconUrl(weather.weather[0].icon)}
            alt="Weather icon"
          />
          <div className="flex flex-col text-center">
            <p className={`font-semibold text-sm ${textColor}`}>
              {Math.round(weather.main.temp)}°C
            </p>
            <p className={`font-thin -mt-1 text-xs ${textColor}`}>
              {weather.weather[0].main}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeatherWidget;
