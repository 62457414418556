import React from "react";
import StorySection from "../components/storySection";
import TeamSection from "../components/teamSection";
import AppNav from "../components/appNav";
import Footer from "../components/footer";
import Hero from "../components/hero";
import { useTranslation } from "react-i18next";

const TeamPage = () => {
  const {t} = useTranslation()

  return (
    <>
      <div className="flex flex-col min-h-screen kode-bg team-bg">
        <AppNav textColor="text-default-text" />
        <Hero title={t("A Symphony of Expertise:")} text={t("Unveiling the KODE Team")} />
      </div>
      <StorySection />
      <TeamSection />
      <Footer />
    </>
  );
};

export default TeamPage;
