import React from "react";
import mapImg from "./../assets/img/worldMap.png";
import CategoryItem from "./categoryItem";
import { categories } from "../utils/data/categoriesData";
import { mapPoints } from "../utils/data/mapPointsData";
import MapPoint from "./mapPoint";
import { useTranslation } from "react-i18next";

const WorldMap = () => {
  const {t} = useTranslation();

  return (
    <div>
      <div className="relative w-full h-full">
        <img src={mapImg} alt="map" className="w-full h-full object-cover" />
        {mapPoints.map((point, index) => (
          <MapPoint
            key={index}
            pointPosition={point.pointPosition}
            pointColor={point.pointColor}
            pointText={point.pointText}
            pointBgColor={point.pointBgColor}
          />
        ))}
      </div>

      <ul className="grid grid-cols-2 gap-10 sm:flex sm:justify-between mt-14 ">
        {categories.map((category, index) => (
          <CategoryItem
            key={index}
            text={t(category.text)}
            borderColor={category.borderColor}
            iconColor={category.iconColor}
          />
        ))}
      </ul>
    </div>
  );
};

export default WorldMap;
