import React from "react";
import { SegmentCardProps } from "../types/appTypes";

const SegmentCard: React.FC<SegmentCardProps> = ({
  title,
  description,
  amount,
  text,
}) => {
  return (
    <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-2xl">
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <p className="text-dark-text mb-8">{description}</p>
      <div className="text-center border-t border-gray-300 pt-8">
        <p className="text-lg font-semibold mb-3 text-dark-text">{text}</p>
        <p className="text-4xl font-semibold text-purple-600">{amount}</p>
      </div>
    </div>
  );
};

export default SegmentCard;
