import React from "react";
import SegmentCard from "./segmentCard";
import { useTranslation } from "react-i18next";

const SegmentsSection: React.FC = () => {
  const { t } = useTranslation();

  const segments = [
    {
      title: `${t("Segmento")} I`,
      description: t("Pequeñas empresas entre 10 y menos de 50 empleados."),
      text: t("Hasta"),
      amount: "12.000€",
    },
    {
      title: `${t("Segmento")} II`,
      description:
        t("Pequeñas empresas o Microempresas entre 3 y menos de 10 empleados."),
      text: t("Hasta"),
      amount: "6.000€",
    },
    {
      title: `${t("Segmento")} III`,
      description:
        t("Pequeñas empresas o Microempresas de entre 0 y menos de 3 empleados."),
      text: t("Hasta"),
      amount: "2.000€",
    },
  ];

  return (
    <section>
      <div className="container pb-20 px-4 md:px-10">
        <h2 className="text-center mb-8">{t("Segmentos")}</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {segments.map((segment, index) => (
            <SegmentCard
              key={index}
              text={segment.text}
              title={segment.title}
              description={segment.description}
              amount={segment.amount}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SegmentsSection;
