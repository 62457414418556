import { Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import AboutUsPage from "../pages/OurFootPrintPage";
import ContactsPage from "../pages/GetInTouch";
import TeamPage from "../pages/TeamPage";
import HomePage from "../pages/HomePage";
import ServicesPage from "../pages/ServicesPage";
import KitDigitalPage from "../pages/KitDigitalPage";

const RoutesContainer = () => {
  const location = useLocation();

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={location.pathname}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <Routes location={location}>
          <Route path="/" element={<HomePage />} />
          <Route path="/our-footprint" element={<AboutUsPage />} />
          <Route path="/get-in-touch" element={<ContactsPage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/kit-digital" element={<KitDigitalPage />} />
        </Routes>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default RoutesContainer;
