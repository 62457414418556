import React, { useEffect, useState } from "react";
import HeroSlider, { Nav, Slide, Overlay } from "hero-slider";
import bg1 from "./../assets/img/home-bg-one.jpeg";
import bg2 from "./../assets/img/home-bg-two.jpeg";
import bg3 from "./../assets/img/home-bg-three.jpeg";
import ProgressBar from "../components/progressBar";
import AppNav from "./appNav";
import CTABtn from "./ctaBtn";
import { useTranslation } from "react-i18next";

const HeroHome = () => {
  const { t } = useTranslation();
  const [hasBeenShown, setHasBeenShown] = useState(false);
  const [progress, setProgress] = useState(0);
  const duration = 100;

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress: number) => {
        const newProgress = oldProgress + 10 / duration;
        return newProgress > 100 ? 100 : newProgress;
      });
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [duration]);

  const handleAfterSliding = () => {
    setProgress(0);
    if (hasBeenShown) return;
    setHasBeenShown(true);
  };

  const slideClass = hasBeenShown
    ? "fade-in-focus-effect-delay"
    : "fade-in-focus-effect";

  return (
    <>
      <HeroSlider
        height="100vh"
        animations={{
          slidingAnimation: "fade",
          sliderFadeInDuration: 2000,
        }}
        autoplay={{
          autoplayDuration: 10000,
        }}
        accessibility={{
          shouldDisplayButtons: false,
        }}
        controller={{
          initialSlide: 1,
          slidingDuration: 2000,
          slidingDelay: 0,
          onBeforeSliding: handleAfterSliding,
        }}
      >
        <Overlay>
          <AppNav textColor="text-black-color" />
        </Overlay>

        <Slide
          background={{
            backgroundImageSrc: bg1,
            backgroundAnimation: "zoom",
            backgroundAnimationDuration: "3000",
          }}
        >
          <div
            className={`fade-in-focus-effect text-center px-10 py-10 container mx-auto w-full h-full flex flex-col justify-center items-center ${slideClass}`}
          >
            <h1 className="mb-3 text-black-text text-4xl md:text-5xl">
              {t("Ignite Innovation with")}
              <span style={{ whiteSpace: "nowrap" }}>
                <span className=" -mr-3">K</span>{" "}
                <span className="text-accent-yellow -mr-3">O</span>{" "}
                <span className="text-accent-blue -mr-3">D</span>{" "}
                <span className="text-accent-red-300">E</span>
              </span>{" "}
            </h1>
            <p className="mb-10 font-thin text-xl md:text-2xl max-w-sm text-black-text">
              {t("Transforming Ideas into Digital Masterpieces!")}
            </p>
            <CTABtn text={t("Get Inspired")} />
          </div>
        </Slide>
        <Slide
          background={{
            backgroundImageSrc: bg2,
            backgroundAnimation: "zoom",
            backgroundAnimationDuration: "3000",
          }}
        >
          <div className="fade-in-focus-effect text-center px-10 py-10  container mx-auto w-full h-full flex flex-col justify-center items-center fade-in-focus-effect-delay">
            <h1 className="mb-6 text-default-text text-4xl md:text-5xl max-w-2xl">
              {t("Crafting Tomorrow's Digital Experience Today")}
            </h1>
            <p className="mb-10 font-thin text-xl md:text-2xl max-w-md">
              {t("Empowering Businesses through Agile Excellence")}
            </p>
            <CTABtn text={t("Start Your Project")} />
          </div>
        </Slide>
        <Slide
          background={{
            backgroundImageSrc: bg3,
            backgroundAnimation: "zoom",
            backgroundAnimationDuration: "3000",
          }}
        >
          <div className="fade-in-focus-effect text-center px-10 py-10  container mx-auto w-full h-full flex flex-col justify-center items-center fade-in-focus-effect-delay">
            <h1 className="mb-6 text-default-text text-4xl md:text-5xl">
              {t("Beyond Code, Building Experiences")}
            </h1>
            <p className="mb-10 font-thin text-xl md:text-2xl">
              {t("Inclusive, Accessible,")} <br></br>{" "}
              {t("Ethical - Your Digital Partner")}
            </p>
            <CTABtn text={t("Connect with Us")} />
          </div>
        </Slide>
        <Nav activeColor="#D94F4F" />

        <ProgressBar progress={progress} />
      </HeroSlider>
    </>
  );
};

export default HeroHome;
