import React from "react";
import desktopImg from "./../assets/img/desktop-image.png";
import { useTranslation } from "react-i18next";

const AppsDescriptionSection = () => {
  const { t } = useTranslation();

  return (
    <section>
      <div className="container pt-10 pb-20 md:pb-10 md:pt-20 md:px-10 px-4">
        <h1 className="md:text-center mb-10">
          {t("Tailored Solutions for Your Business")}
        </h1>
        <p className="font-thin md:text-center mb-20 text-black-text text-lg">
          {t(
            "Your business is unique, and so are our solutions. We're here to understand your needs and offer you the best solution. Our commitment is to provide tailored solutions that elevate your business."
          )}
        </p>
        <div className="flex items-center relative justify-center flex-col gap-10 md:gap-5 md:flex-row ">
          <div className="md:w-1/2 w-full flex justify-center">
            <img
              src={desktopImg}
              alt="desktop"
              className="object-cover object-center w-full"
            />
          </div>
          <div className="md:w-1/2 w-full">
            <h1 className="mb-5">Inclusive, Accessible, and Ethical Apps</h1>
            <p className="font-thin md:text-justify text-black-text text-lg max-w-sm lg:max-w-md">
              {t(
                "We take pride in crafting inclusive, accessible, and ethical applications. Our thoughtful practices go beyond functionality to ensure that our apps are designed with integrity, making a positive impact on users."
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppsDescriptionSection;
