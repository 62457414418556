import React from "react";
import useWeather from "../utils/hooks/useWeather";
import { industries } from "../utils/data/industryCardsData";
import IndustryCard from "./industryCard";
import { useTranslation } from "react-i18next";

const IndustriesSection = () => {
  const { t } = useTranslation();
  const { backgroundColorLight } = useWeather();
  return (
    <section style={{ backgroundColor: backgroundColorLight }}>
      <div className="container">
        <div className="py-20 md:px-10 px-4">
          <h1 className="text-black-text text-center mb-3">
            {t("Apply to Various Industries")}
          </h1>
          <p className="text-center font-thin text-black-text text-lg mb-10">
            {t("Adaptability for Success in Every Business Landscape.")}
          </p>

          <div className="bg-industries-bg p-8 lg:py-12 lg:px-20  rounded-xl">
            <div className="grid grid-cols-2 md:grid-cols-4 justify-center gap-4 lg:gap-y-6">
              {industries.map((industry) => (
                <IndustryCard
                  key={industry.title}
                  title={industry.title}
                  imageSrc={industry.imageSrc}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndustriesSection;
