import React from "react";
import ContactCard from "./contactCard";
import { contacts } from "../utils/data/contactsData";
import { useTranslation } from "react-i18next";

const ContactsSection = () => {
  const {t} = useTranslation()

  return (
    <section className="bg-accent-red mt-auto">
      <div className="container">
        <div className="px-6 py-10 flex flex-wrap md:justify-between items-center flex-col lg:flex-row gap-10">
          {contacts.map((contact, index) => (
            <ContactCard
              key={index}
              cardIcon={contact.cardIcon}
              title={t(contact.title)}
              text={t(contact.text)}
              lowerText={t(contact.lowerText)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ContactsSection;
