import React from "react";
import SwiperComponent from "./swiper";
import { teamMembers } from "../utils/data/teamMembersData";
import { useTranslation } from "react-i18next";

const TeamSection = () => {
  const { t } = useTranslation();
  return (
    <section className="bg-accent-blue">
      <div className="container py-20 px-4">
        <h2 className="text-center mb-3 font-semibold">
          {t("Faces Behind the Innovation")}
        </h2>
        <p className="text-black-text text-2xl text-center">
          {t("Exploring the")}
          <span style={{ whiteSpace: "nowrap" }}>
            <span className=" -mr-1">K </span>
            <span className="text-accent-yellow -mr-1">O </span>
            <span className="text-default-text -mr-1">D </span>
            <span className="text-accent-red-300">E </span>
          </span>
          {t("Team's Tale")}
        </p>
        <div className="mx-auto my-10 max-w-6xl">
          <SwiperComponent teamMembers={teamMembers} />
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
