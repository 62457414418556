import { IndustryCardProps } from "../types/appTypes";

const IndustryCard: React.FC<IndustryCardProps> = ({ title, imageSrc }) => {
  return (
    <div className="mx-auto flex flex-col items-center">
      <div className="image-wrapper mb-5 flex justify-center items-center">
        <div className="industry-border ">
          <div className="w-24 h-24  rounded-full overflow-hidden border-4 border-white">
            <img
              src={imageSrc}
              alt={title}
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <h2 className="text-sm font-thin text-default-text max-w-[100px] text-center">
        {title}
      </h2>
    </div>
  );
};

export default IndustryCard;
