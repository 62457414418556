import React from "react";
import PartnetsSection from "../components/partnetsSection";
import ExcellenceSection from "../components/excellenceSection";
import AppsDescriptionSection from "../components/appsDescriptionSection";
import HeroHome from "../components/heroHome";
import Footer from "../components/footer";
import useWeather from "../utils/hooks/useWeather";
import IndustriesSection from "../components/industriesSection";

const HomePage = () => {
  const { backgroundColorDark } = useWeather();

  return (
    <div style={{ backgroundColor: backgroundColorDark }}>
      <HeroHome />
      <ExcellenceSection />
      <AppsDescriptionSection />
      <IndustriesSection />
      <PartnetsSection />
      <Footer />
    </div>
  );
};

export default HomePage;
