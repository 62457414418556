import React from "react";
import desk from "./../assets/img/team-section-story.jpg";
import { useTranslation } from "react-i18next";

const StorySection = () => {
  const {t} = useTranslation()

  return (
    <section>
      <div className="container py-20">
        <h2 className="text-center mb-10 font-semibold">
         {t("Our Origins and Story")}
        </h2>
        <div className="px-6 gap-10 lg:gap-20 mx-auto flex w-full justify-center items-center flex-col md:flex-row">
          <div className="max-w-xs lg:max-w-md">
            <img src={desk} alt="desk" className="object-cover object-center" />
          </div>
          <div className="flex flex-col w-full gap-4">
            <p className="text-black-text lg:text-lg">
              {t("Diversity is the heartbeat of our team – a collective of developers, designers, strategists, and visionary thinkers.")}
            </p>
            <p className="text-black-text lg:text-lg">
              <span style={{ whiteSpace: "nowrap" }}>
                <span className=" -mr-1">K</span>{" "}
                <span className="text-accent-yellow -mr-1">O</span>{" "}
                <span className="text-accent-blue -mr-1">D</span>{" "}
                <span className="text-accent-red-300">E</span>
              </span>{" "}
           {t("is your all-encompassing celebration box, seamlessly blending technology, design, and flawless execution. Our mission is to pave your path to success amidst competition, ever-changing demands, and evolving technologies.")}
            </p>
            <p className="text-black-text lg:text-lg">
              {t("Over a decade of steering successful projects, we've redefined digital innovation. Our solutions transcend boundaries, embodying simplicity, usability, and impact.")}
            </p>
            <p className="text-black-text lg:text-lg">
              {t("With your audience at the forefront, we pose a pivotal question throughout each project:")}
              <span className="font-semibold">
               {t("'Does it fuel your business growth?'")}
              </span>{" "}
             {t("This mantra ties into our exquisite designs, whether in web or mobile domains, intricately woven with measurable metrics for you to track, test, and validate.")}
            </p>
            <p className="text-black-text lg:text-lg">
              {t("To stay at the forefront, we constantly refine our digital skills, tackling intricate tasks to stay agile amidst emerging technologies.")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StorySection;
