import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AppLinkType } from "../types/appTypes";

const AppLink: React.FC<AppLinkType> = ({ path, text }) => {
  const location = useLocation();

  return (
    <Link
      to={path}
      className={
        location.pathname === path
          ? "active-link text-sm"
          : "font-semibold text-sm hover:text-accent-red transition duration-300"
      }
    >
      {text}
    </Link>
  );
};

export default AppLink;
