import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  tel: Yup.string()
    .matches(
      /^[0-9+()]{8,}$/,
      "Telephone must contain only numbers and be at least 8 characters long"
    )
    .required("Telephone is required"),
  message: Yup.string().required("Message is required"),
});
