import React, { useState } from "react";
import infoIcon from "./../assets/img/info-blue.svg";
import { FlipCardProps } from "../types/appTypes";

const FlipCard: React.FC<FlipCardProps> = ({
  icon,
  title,
  text,
  iconWidth,
  description,
  iconMargin,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <li
      className={`relative transition-transform hover-animation w-[200px] h-[200px] lg:w-[250px] lg:h-[250px]  ${
        isFlipped ? "flipped" : ""
      }`}
    >
      <div className="card w-full h-full">
        <div className="p-2 card-front border border-accent-blue rounded-md  flex w-full h-full flex-col justify-center items-center text-center">
          <img
            className={`${iconMargin} ${iconWidth}`}
            src={icon}
            alt="android"
          />
          <h3>{title}</h3>
          <p className="text-black-text lg:text-2xl text-xl">{text}</p>

          <img
            src={infoIcon}
            alt="info"
            className="absolute w-5 top-2 right-2 cursor-pointer"
            onClick={handleFlip}
          />
        </div>
        <div className="p-3 card-back w-full h-full border border-accent-blue rounded-md">
          <p className="text-black-text font-thin leading-relaxed text-xs lg:text-md mt-5 lg:leading-loose">
            {description}
          </p>
          <img
            src={infoIcon}
            alt="info"
            className="absolute w-5 top-2 right-2 cursor-pointer"
            onClick={handleFlip}
          />
        </div>
      </div>
    </li>
  );
};

export default FlipCard;
