import { useTranslation } from "react-i18next";
import { servicesCards } from "../utils/data/servicesCardsData";
import FlipCard from "./flipCard";

const InnovationsSection = () => {
  const {t} = useTranslation();
  
  return (
    <section>
      <div className="container py-20 md:px-10 px-4 flex flex-col items-center">
        <h1 className="text-center text-services-text-color mb-20">
          {t("Where Precision Meets Innovation:")}<br></br>
          {t("Boutique Development for Web and Mobile Solutions")}
        </h1>
        <ul className="flex flex-wrap gap-20 justify-center lg:gap-30 max-w-6xl">
          {servicesCards.map((card, index) => (
            <FlipCard
              key={index}
              icon={card.icon}
              title={t(card.title)}
              text={t(card.text)}
              description={t(card.description)}
              iconMargin={card.iconMargin}
              iconWidth={card.iconWidth}
            />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default InnovationsSection;
