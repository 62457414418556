import React from "react";
import { HeroProps } from "../types/appTypes";
import logo from "../assets/img/logo.png";
import { useTranslation } from "react-i18next";

const HeroKitGigital: React.FC<HeroProps> = ({ title, text }) => {
  const {t} = useTranslation();
  
  return (
    <header className="px-10 w-full flex mx-auto max-w-5xl justify-between items-center">
      <div className="md:text-center pr-3 py-20  max-w-2xl">
        <h1 className="capitalize text-black mb-6 text-xl md:text-5xl font-normal">
          {title}
        </h1>
        <p className="text-black text-base md:text-xl ">{text}</p>
      </div>

      <div className="text-center flex flex-col items-center md:w-72 md:pt-5 md:pb-10 p-5 md:px-8 bg-white rounded-lg">
        <img src={logo} alt="logo" className="w-20 md:w-32 mb-4" />
        <p className="text-dark-text md:text-xl font-semibold mb-2">
         {t("¡Comienza Ahora!")}
        </p>
        <p className="text-dark-text md:text-xl font-semibold  mb-4">{t("Contáctanos")}</p>
        <div className="flex flex-col space-y-4 w-full">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://wa.me/34652598032"
            className="bg-accent-blue text-black text-xs md:text-sm  font-semibold w-full py-2 px-4 rounded-lg"
          >
            Whatsapp
          </a>
          <a
            href="mailto:kode@zone.com"
            className="bg-yellow-400 text-black text-xs md:text-sm font-semibold   w-full py-2 px-4 rounded-lg"
          >
            {t("Email")}
          </a>
        </div>
      </div>
    </header>
  );
};

export default HeroKitGigital;
