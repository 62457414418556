import AI from "./../../assets/img/AI-industry.jpeg";
import fitness from "./../../assets/img/FITNESS-industry.jpeg";
import eCommerce from "./../../assets/img/E-COMMERCE-industry.jpeg";
import learning from "./../../assets/img/machine-learning.jpeg";
import eTravel from "./../../assets/img/E-TRAVEL-industry.jpeg";
import gaming from "./../../assets/img/GAMING-industry.jpeg";
import server from "./../../assets/img/SERVER-industry.jpeg";
import crypto from "./../../assets/img/CRYPTO-industry.jpeg";

export const industries = [
  { title: "FITNESS", imageSrc: fitness },
  { title: "E-COMMERCE", imageSrc: eCommerce },
  { title: "MACHINE LEARNING", imageSrc: learning },
  { title: "E-TRAVEL", imageSrc: eTravel },
  { title: "GAMING", imageSrc: gaming },
  { title: "AI", imageSrc: AI },
  { title: "SERVER INFRASTRUCTURE", imageSrc: server },
  { title: "CRYPTO", imageSrc: crypto },
];
