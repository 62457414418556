// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import KitDigitalCard from "./kitDigitalCard";
import icon2 from "../assets/img/wed.webp";
import icon1 from "../assets/img/ecommerce.webp";
import icon3 from "../assets/img/socialmedia.webp";
import icon4 from "../assets/img/Fact.png";
import icon5 from "../assets/img/BA&BI.webp";
import icon6 from "../assets/img/MKP.webp";
import icon7 from "../assets/img/PresenciaAvanzada.webp";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";

const SolutionsSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section>
      <div className="container pb-20 px-4 md:px-10">
        <h2 className="text-center mb-8">{t("Soluciones")}</h2>
        <div className="relative flex justify-center items-center w-full">
          <Swiper
            slidesPerView={3}
            className="solutions-swiper"
            spaceBetween={20}
            modules={[Autoplay]}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 5,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1020: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            <SwiperSlide className="solutions-swiper-slide">
              <KitDigitalCard
                title={t("Comercio Electronico")}
                descriptionTop={t(
                  "Diseñamos sitios web para la compraventa de productos o servicios que utilizan plataformas digitales para facilitar el intercambio."
                )}
                descriptionBottom={t(
                  "No solo aumentará la visibilidad de tu marca, sino que también tendrás un e-commerce o tienda en línea con diseño adaptable y optimización para motores de búsqueda."
                )}
                text={t("Ayuda Maxima")}
                price="2.000€"
                icon={icon1}
              />
            </SwiperSlide>
            <SwiperSlide className="solutions-swiper-slide">
              <KitDigitalCard
                title={t("Sitio Web")}
                descriptionTop={t(
                  "Creamos sitios web para darle a tu negocio una mayor presencia en internet, con un dominio propio y un diseño profesional adaptable a distintos dispositivos."
                )}
                descriptionBottom={t(
                  "Estos sitios son optimizados para ser rastreados por los principales motores de búsqueda."
                )}
                text={t("Ayuda Maxima")}
                price="2.000€"
                icon={icon2}
              />
            </SwiperSlide>
            <SwiperSlide className="solutions-swiper-slide">
              <KitDigitalCard
                title={t("Redes Sociales")}
                descriptionTop={t(
                  "Desarrollamos y gestionamos contenido y estrategias de social media para al menos una red social."
                )}
                descriptionBottom={t(
                  "Te proporcionamos una estrategia personalizada para tus redes sociales, diseñada específicamente para alcanzar tus objetivos."
                )}
                text={t("Ayuda Maxima")}
                price="2.000€"
                icon={icon3}
              />
            </SwiperSlide>
            <SwiperSlide className="solutions-swiper-slide">
              <KitDigitalCard
                title={t("Factura Electrónica")}
                descriptionTop={t(
                  "Ofrecemos soluciones de facturación electrónica que simplifican y automatizan el proceso de emisión y recepción de facturas digitales."
                )}
                descriptionBottom={t(
                  "Con nuestra herramienta, podrás gestionar tus facturas de manera eficiente, cumpliendo con las normativas legales y mejorando la precisión y la velocidad de tus procesos administrativos."
                )}
                text={t("Ayuda Maxima")}
                price="2.000€"
                icon={icon4}
              />
            </SwiperSlide>
            <SwiperSlide className="solutions-swiper-slide">
              <KitDigitalCard
                title={t("BA & BI Analytics")}
                descriptionTop={t(
                  "Proveemos servicios de Business Analytics y Business Intelligence que transforman tus datos en información valiosa para la toma de decisiones."
                )}
                descriptionBottom={t(
                  "Mediante la implementación de técnicas avanzadas de análisis de datos, ayudamos a tu empresa a identificar tendencias, optimizar operaciones y maximizar el rendimiento."
                )}
                text={t("Ayuda Maxima")}
                price="2.000€"
                icon={icon5}
              />
            </SwiperSlide>
            <SwiperSlide className="solutions-swiper-slide">
              <KitDigitalCard
                title={t("Marketplace")}
                descriptionTop={t(
                  "Desarrollamos y gestionamos marketplaces, espacios digitales donde múltiples vendedores pueden ofrecer sus productos o servicios en una plataforma común."
                )}
                descriptionBottom={t(
                  "Nuestro servicio incluye diseño adaptable, optimización SEO y funcionalidades avanzadas para facilitar la interacción entre compradores y vendedores."
                )}
                text={t("Ayuda Maxima")}
                price="2.000€"
                icon={icon6}
              />
            </SwiperSlide>
            <SwiperSlide className="solutions-swiper-slide">
              <KitDigitalCard
                title={t("Presencia Avanzada en Internet")}
                descriptionTop={t(
                  "Mejoramos la presencia en línea de tu negocio mediante estrategias avanzadas de optimización y marketing digital."
                )}
                descriptionBottom={t(
                  "Implementamos técnicas de SEO avanzadas, gestión de contenido y publicidad online para aumentar la visibilidad y atraer a un público objetivo más amplio."
                )}
                text={t("Ayuda Maxima")}
                price="2.000€"
                icon={icon7}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default SolutionsSection;
