import React from "react";
import logo from "./../assets/img/logo.png";
import twitterIcon from "./../assets/img/twitter2.svg";
import instagramIcon from "./../assets/img/instagram.svg";
import linkedinIcon from "./../assets/img/linkedin.svg";
import telIcon from "./../assets/img/phone.png";
import emailIcon from "./../assets/img/email-2.png";
import LanguageSwitcher from "./languageSwitcher";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  
  return (
    <footer className="bg-page">
      <div className="container">
        <div className=" md:py-2 py-4 md:px-10 px-4">
          <a href="/" rel="noreferrer" target="_blank">
            <img src={logo} alt="logo" className="md:w-48 w-40 mb-2 mt-4" />
          </a>

          <div className="flex md:justify-between flex-col md:flex-row border-b border-solid border-decoration md:pb-4 pb-8">
            <div className="md:max-w-sm mb-8 md:mb-0">
              <p className="text-black-text max-w-xs md:mb-4 mb-8 text-md">
               {t("At KODE, Clients approach us because we're small enough to care, yet skilled enough to deliver on their brand's biggest promises. We do partnerships, not sales. Our aim? To turn your 'vague ideas' into soul-stirring experiences that delight customers and make you profitable.")}
              </p>
              <div className="flex md:block justify-between sm:px-10">
                <div className="flex flex-col md:flex-row md:justify-between md:max-w-[250px] gap-4 md:gap-0">
                  <h4 className="uppercase text-base font-bold">{t("Follow us")}</h4>
                  <ul className="flex gap-4">
                    <li>
                      <a
                        href="https://twitter.com/WeKodeYourIdeas"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          src={twitterIcon}
                          alt="twitter-icon"
                          className="w-6 h-8"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/we.kode.your.ideas/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={instagramIcon}
                          alt="instagram-icon"
                          className="w-5 h-8"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/kodeyourideas"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={linkedinIcon}
                          alt="linkedin-icon"
                          className="w-7 h-8"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col md:hidden">
                  <h5 className="font-bold mb-4">{t("Contact with Us")}</h5>
                  <ul className="flex flex-col items-start">
                    <li className="mb-5">
                      <a href="tel:+34691381326" className="flex items-end">
                        <img
                          src={telIcon}
                          alt="icon-tel"
                          className="w-[20px] h-[18px] mr-2"
                        />
                        <p className="text-black-text">+34 691 381 326</p>
                      </a>
                    </li>
                    <li>
                      <a href="mailto:hola@kode.zone" className="flex">
                        <img
                          src={emailIcon}
                          alt="icon-email"
                          className="w-[18px] h-[18px] mr-2"
                        />
                        <p className="text-black-text">hola@kode.zone</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="md:flex flex-col hidden ">
              <h5 className="font-bold mb-4">{t("Contact with Us")}</h5>
              <ul className="flex flex-col items-start">
                <li className="mb-5">
                  <a href="tel:+34691381326" className="flex items-end">
                    <img
                      src={telIcon}
                      alt="icon-tel"
                      className="w-[20px] h-[18px] mr-2"
                    />
                    <p className="text-black-text">+34 691 381 326</p>
                  </a>
                </li>
                <li>
                  <a href="mailto:hola@kode.zone" className="flex">
                    <img
                      src={emailIcon}
                      alt="icon-email"
                      className="w-[18px] h-[18px] mr-2"
                    />
                    <p className="text-black-text">hola@kode.zone</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="py-4 md:px-10">
          <div className="flex md:justify-between justify-center items-center md:flex-row flex-col gap-2">
          <LanguageSwitcher />
            <ul className="flex flex-wrap items-center">
              <li>
                <a
                  className="border-r border-solid border-decoration text-footer-light-text text-xs md:px-2 px-1"
                  href="https://www.termsfeed.com/live/a28e543e-b51b-4f2d-9709-7eaf984f097d"
                  target="_blank"
                  rel="noreferrer"
                >
                 {t("Terms and conditions")}
                </a>
              </li>
              <li>
                <a
                  className="border-r border-solid border-decoration text-footer-light-text text-xs md:px-2 px-1"
                  href="https://intranet.laboralrgpd.com/politicaPrivacidad/index.php?sid=a2dzQnJMWEl3UDgxczN6K2dFZ2pVQT09"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("Privacy")}
                </a>
              </li>
              <li>
                <a
                  href="https://kode.zone/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-footer-light-text text-xs md:pl-2 pl-1"
                >
                  {t("Powered by KODE")}
                </a>
              </li>
            </ul>
              
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
