import React from "react";
import banner from "../assets/img/Logo-digitalizadores.png"

const BannerSection = () => {
  return (
    <section className="w-full bg-white mx-auto">
      <img className="object-cover w-full" src={banner} alt="banner" />
    </section>
  );
};

export default BannerSection;
