import icon1 from "./../../assets/img/innovation-1.png";
import icon2 from "./../../assets/img/innovation-2.png";
import icon3 from "./../../assets/img/innovation-3.png";
import icon4 from "./../../assets/img/innovation-4.png";
import icon5 from "./../../assets/img/innovation-5.png";
import icon6 from "./../../assets/img/innovation-6.png";

export const servicesCards = [
  {
    icon: icon1,
    title: "iOS / Android",
    text: "Development",
    description:
      "Crafting dynamic and user-centric applications for both web and mobile platforms. From intuitive UI/UX to robust back-end development, we bring your digital vision to life.",
    iconWidth: "w-24",
    iconMargin: "mb-5",
  },
  {
    icon: icon2,
    title: "Mobile",
    text: "Architecture",
    description:
      "Designing scalable and resilient mobile architectures to support the seamless functioning of your mobile applications.",
    iconWidth: "w-24",
    iconMargin: "mb-1",
  },
  {
    icon: icon3,
    title: "Software",
    text: "Development",
    description:
      "Full-cycle software development services, encompassing ideation, coding, testing, and maintenance. Your digital solutions, our expertise.",
    iconWidth: "w-20",
    iconMargin: "mb-2",
  },
  {
    icon: icon4,
    title: "Project",
    text: "Managment",
    description:
      "From conception to completion, our project management expertise ensures that your digital endeavors stay on track, on time, and on budget.",
    iconWidth: "w-20",
    iconMargin: "mb-2",
  },
  {
    icon: icon5,
    title: "Video & Photography",
    text: "",
    description:
      "Elevate your brand with captivating visuals. Our video and photography services capture and convey your narrative with precision and creativity.",
    iconWidth: "w-16",
    iconMargin: "mb-5",
  },
  {
    icon: icon6,
    title: "Web",
    text: "Solutions",
    description:
      "Crafting bespoke web solutions that combine aesthetic design with powerful functionality, ensuring a compelling online presence for your brand.",
    iconWidth: "w-16",
    iconMargin: "mb-5",
  },
];
