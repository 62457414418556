import React from "react";
import { kitDigitalCardProps } from "../types/appTypes";

const KitDigitalCard: React.FC<kitDigitalCardProps> = ({
  title,
  descriptionTop,
  descriptionBottom,
  text,
  price,
  icon,
}) => {
  return (
    <div className="bg-accent-blue text-white relative w-full h-full  p-6 rounded-lg flex flex-col items-center justify-between">
      <div className="flex flex-col items-center">
        <img src={icon} alt={title} className="w-20 h-20 mb-4" />
        <h4 className="mb-2 text-center">{title}</h4>
      </div>
      <div>
        <p className="text-sm text-black font-thin mb-3">{descriptionTop}</p>
        <p className="text-sm mb-4 text-black font-thin">{descriptionBottom}</p>
      </div>
      <div className="flex flex-col items-center">
        <p className="text-lg">{text}</p>
        <p className="text-lg">{price}</p>
      </div>
    </div>
  );
};

export default KitDigitalCard;

