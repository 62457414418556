import React from "react";
import { ProgressBarProps } from "../types/appTypes";

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <div
      className="absolute bottom-0 progress-bar -left-1"
      style={{ width: "100%", height: "3px", backgroundColor: "transparent" }}
    >
      <div
        className="rounded-md"
        style={{
          width: `${progress}%`,
          height: "100%",
          backgroundColor: "#D94F4F",
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
