import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import RoutesContainer from "./components/routesContainer";
import "./i18n"

const App = () => {
  return (
    <Router>
        <RoutesContainer />
    </Router>
  );
};

export default App;
