import React, { useEffect, useRef } from "react";
import Map from "./googleMap";
import emailjs from "@emailjs/browser";
import { FormValues } from "../types/appTypes";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { validationSchema } from "../utils/validationSchemas";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const { t } = useTranslation();
  const form = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const section = document.querySelector(hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const EMAIL_SERVICE_ID = process.env.REACT_APP_EMAIL_SERVICE_ID || "";
  const EMAIL_TEMPLATE_ID = process.env.REACT_APP_EMAIL_TEMPLATE_ID || "";
  const EMAIL_PUBLIC_KEY = process.env.REACT_APP_EMAIL_PUBLIC_KEY || "";

  const sendEmail = (
    formElement: HTMLFormElement | null,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    if (formElement) {
      emailjs
        .sendForm(
          EMAIL_SERVICE_ID,
          EMAIL_TEMPLATE_ID,
          formElement,
          EMAIL_PUBLIC_KEY
        )
        .then(
          (result) => {
            formikHelpers.resetForm();
          },
          (error) => {
            console.log(error.text);
          }
        )
        .catch((error) => {
          console.error("Error sending email:", error);
        });
    } else {
      console.log("Form is not available");
    }
  };

  return (
    <section className="bg-form-bg">
      <div className="container">
        <div className="px-6 py-10 mx-auto flex w-full flex-wrap max-w-7xl md:justify-between items-center flex-col lg:flex-row">
          <div className="lg:w-1/2 mb-5 lg:mb-0 flex">
            <Map />
          </div>
          <div
            className="lg:w-1/2 max-w-md lg:max-w-xl mt-10 lg:mt-0"
            id="form"
          >
            <h2 className="mb-2">{t("Let's Chat")}</h2>
            <p className="text-black mb-3">
              {t("Let us empower your business with innovative solutions tailored to your unique goals. Reach out to us today and let's create something amazing together!")}
            </p>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                message: "",
                tel: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, formikHelpers) => {
                sendEmail(form.current, formikHelpers);
              }}
            >
              {({ errors, touched }) => (
                <Form className="w-full" ref={form}>
                  <div className="flex flex-col sm:flex-row w-full gap-4 mb-3">
                    <div className="flex flex-col w-full">
                      <label htmlFor="firstName">
                        {t("Name")}:
                        <Field
                          id="firstName"
                          name="firstName"
                          className="form-input"
                        />
                        {touched.firstName && errors.firstName && (
                          <div className="error-message">
                            {errors.firstName}
                          </div>
                        )}
                      </label>
                    </div>

                    <div className="flex flex-col w-full">
                      <label htmlFor="lastName">
                        {t("Last Name")}:
                        <Field
                          name="lastName"
                          id="lastName"
                          className="form-input"
                        />
                        {touched.lastName && errors.lastName && (
                          <div className="error-message">{errors.lastName}</div>
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row w-full gap-4 mb-3">
                    <div className="flex flex-col w-full">
                      <label htmlFor="email">
                        {t("Email")}:
                        <Field
                          name="email"
                          id="email"
                          className="form-input"
                        />
                        {touched.email && errors.email && (
                          <div className="error-message">{errors.email}</div>
                        )}
                      </label>
                    </div>

                    <div className="flex flex-col w-full">
                      <label htmlFor="tel">
                        {t("Telephone")}:
                        <Field
                          name="tel"
                          id="tel"
                          className="form-input"
                        />
                        {touched.tel && errors.tel && (
                          <div className="error-message">{errors.tel}</div>
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="flex flex-col mb-2">
                    <label htmlFor="message">
                      {t("Message")}:
                      <Field
                        as="textarea"
                        className="form-input"
                        id="message"
                        name="message"
                      />
                      {touched.message && errors.message && (
                        <div className="error-message">{errors.message}</div>
                      )}
                    </label>
                  </div>

                  <button className="submit-btn" type="submit">
                    {t("Submit")}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
