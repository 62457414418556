import React from 'react';
import usaIcon from './../assets/img/icons-usa.png';
import esIcon from './../assets/img/icons-spain.png'

interface FlagIconProps {
    countryCode: string;
}

const FlagIcon: React.FC<FlagIconProps> = ({ countryCode }) => {
    let flagSrc = '';
    switch (countryCode) {
        case 'en':
            flagSrc = usaIcon;
            break;
        case 'es':
            flagSrc = esIcon;
            break;
        default:
            flagSrc = '';
    }

    return (
        <img
            src={flagSrc}
            alt={`${countryCode} flag`}
            className="w-6 h-6 inline-block mr-2"
        />
    );
}

export default FlagIcon;