import React from "react";
import WorldMap from "./worldMap";
import { useTranslation } from "react-i18next";

const OurFootprintSection = () => {
  const {t} =useTranslation();

  return (
    <section>
      <div className="container py-20 px-4 md:px-10">
        <h3 className="text-center mb-6">
          {t("KODE's Journey: From Local to Global")}
        </h3>
        <p className="text-black-text mx-auto font-thin text-center max-w-lg">
          {t("Tenerife, our chosen oasis in the enchanting Island of Eternal Spring. Here, cradled between the lunar Volcano and the azure Ocean, caressed by the gentle Alisi winds, we've curated a haven where vibrant hues dance in harmony with an exceptional climate. Tenerife, with its awe-inspiring landscapes, serves as our canvas to nurture, cultivate, and bring ideas to life. Join us in this thriving ecosystem where creativity blossoms, and imaginations are transformed into tangible realities.")}
        </p>
        <div className="my-20">
          <WorldMap />
        </div>
        <h3>{t("Global Footprint: Sustainable Innovation")}</h3>
        <p className="text-black-text mb-5">{t("Crafting Solutions Worldwide")}</p>
        <p className="text-black-text font-thin mb-10">
          {t("Beyond Tenerife, KODE has left an indelible footprint across the globe. Our projects span the Mexican Riviera Maya, the cultural richness of Italy, the artistic tapestry of France, the canal-laden streets of Amsterdam, the exotic allure of Thailand, the timeless wonders of Egypt, the spice-scented air of Zanzibar, the historic landscapes of Cyprus, the reggae rhythms of Jamaica, the tranquility of Saint Kitts and Nevis, the tropical allure of Balmoral Island, and the dynamic energy of the UK.")}
        </p>
        <h3 className="mb-5">{t("Always Sustainable, Always Responsible:")}</h3>
        <p className="text-black-text font-thin mb-5">
          {t("Our commitment extends beyond innovation—it encompasses sustainability and responsibility. In each location, KODE strives to create solutions that not only meet the present needs but also contribute to a sustainable and responsible future.")}{" "}
        </p>

        <p className="text-black-text font-thin">
          {t("As we expand our creative horizons, our journey continues to evolve, leaving a positive impact on diverse landscapes and communities worldwide. Welcome to KODE's global canvas, where innovation knows no bounds.")}
        </p>
      </div>
    </section>
  );
};

export default OurFootprintSection;
