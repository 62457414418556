import React, { useState } from "react";
import infoIcon from "./../assets/img/info.svg";
import { TeamMember } from "../types/appTypes";

const SwiperCard: React.FC<TeamMember> = ({
  name,
  photo,
  position,
  text,
  flag,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div
      className={`relative max-w-full max-h-full  ${
        isFlipped ? "flipped" : ""
      }`}
    >
      <div className="card">
        <div className="card-front">
          <img
            className="w-[295px] h-[300px] object-cover object-center rounded-md"
            src={photo}
            alt="team-member"
          />
          <div className="absolute rounded-md -bottom-1 left-1/2 transform translate-x-[-50%] bg-accent-blue w-[150px] py-1">
            <div className="text-center text-default-text">
              <h4>{name}</h4>
              <p className="italic uppercase text-lg font-thin">{position}</p>
            </div>
          </div>
          <img
            src={infoIcon}
            className="absolute w-5 top-2 right-2 cursor-pointer"
            alt="information icon"
            onClick={handleFlip}
          />
        </div>
        <div className="card-back border rounded-md">
          <div className="flex flex-col gap-2 justify-between py-1 px-5 text-default-text items-center">
            <div className="flex relative flex-col justify-center items-center">
              <h5>
                {name} {flag}
              </h5>
              <p className="text-sm lg:text:md font-thin">{position}</p>
            </div>
            <p className="text-xs">{text}</p>
            <img
              src={infoIcon}
              className="absolute w-5 top-2 right-2 cursor-pointer"
              alt="information icon"
              onClick={handleFlip}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwiperCard;
