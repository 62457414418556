import React from "react";
import AgileSection from "../components/agileSection";
import InnovationsSection from "../components/innovationsSection";
import HeroServices from "../components/heroServices";
import AppNav from "../components/appNav";
import Footer from "../components/footer";
import { useTranslation } from "react-i18next";

const ServicesPage = () => {
  const {t} = useTranslation();
  return (
    <>
      <div className="flex flex-col min-h-screen kode-bg services-bg">
        <AppNav textColor="text-black-text" />
        <HeroServices
          title={t("Our Services")}
          text={t("At KODE, we excel in transforming ideas into seamless digital experiences. Our team harnesses cutting-edge technology to deliver excellence across diverse domains.")}
        />
      </div>

      <AgileSection />
      <InnovationsSection />
      <Footer />
    </>
  );
};

export default ServicesPage;
