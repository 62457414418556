import React, { useState } from "react";
import logo from "./../assets/img/logo.png";
import closeIcon from "./../assets/img/icons-close.svg";
import menuIcon from "./../assets/img/burger-menu-black.svg";
import { Link } from "react-router-dom";
import AppLink from "./link";
import { NavProps } from "../types/appTypes";
import WeatherWidget from "./weatherWidget";
import { navData } from "../utils/data/navData";
import { useTranslation } from "react-i18next";

const AppNav: React.FC<NavProps> = ({ textColor }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const closeButtonClass = isOpen
    ? "text-default-text rotate-45 icon-spin-open"
    : "text-default-text icon-spin-close";

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Desctop nav */}
      <nav className="flex-none  lg:flex hidden">
        <div className="container">
          <div className="flex justify-between relative items-center px-10 py-6">
            <Link to="/">
              <img src={logo} className="w-24" alt="logo" />
            </Link>
            <div className="flex items-center">
              <ul className={`flex gap-x-5 ${textColor}`}>
                {navData.map((navItem, index) => (
                  <li key={index}>
                        <AppLink path={navItem.path} text={t(navItem.text)} />
                  </li>
                ))}
              </ul>
            </div>
            <div className="  ">
              <WeatherWidget textColor={textColor} />
            </div>
          </div>
        </div>
      </nav>
      {/* Mobile nav */}
      <div
        className={`${
          isOpen ? "translate-x-52" : "translate-x-0"
        } transition-transform duration-300 ease-in-out`}
      >
        <nav className="flex lg:hidden relative justify-between items-center bg-header px-10 py-6">
          <button
            onClick={toggleMenu}
            className={`icon ${isOpen ? "hidden" : ""}`}
          >
            <img src={menuIcon} className={closeButtonClass} alt="menu-icon" />
          </button>
          <div className="absolute left-20 sm:relative sm:left-auto">
            <WeatherWidget textColor={textColor} />
          </div>
          <Link to="/">
            <img src={logo} className="nav-logo w-24" alt="logo" />
          </Link>
        </nav>
      </div>

      {/* SideNav */}
      <div
        className={`fixed top-0 left-0 w-[250px] h-full transform bg-default-text ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-40 p-4 text-center`}
      >
        <div className="relative h-full">
          <h2 className="text-menu-text uppercase font-thin border-b border-solid border-decoration pb-2">
            {t("Menu")}
          </h2>
          <ul className="flex flex-col mt-10 gap-y-10 text-left text-dark-text">
            {navData.map((navItem, index) => (
              <li key={index}>
                <AppLink path={navItem.path} text={t(navItem.text)} />
              </li>
            ))}
          </ul>

          <button
            onClick={toggleMenu}
            className={`absolute top-0 -right-10 icon ${
              isOpen ? "" : "hidden"
            }`}
          >
            <img
              src={closeIcon}
              className={closeButtonClass}
              alt="close-menu"
            />
          </button>
        </div>
      </div>
      {isOpen && (
        <div
          className="fixed inset-0 bg-opacity-60 z-30 bg-black"
          onClick={toggleMenu}
        />
      )}
    </>
  );
};

export default AppNav;
