import React from "react";
import { Link } from "react-router-dom";
import { CTABtnProps } from "../types/appTypes";

const CTABtn: React.FC<CTABtnProps> = ({ text }) => {
  return (
    <Link
      to="/get-in-touch#form"
      className="w-60 py-5 bg-accent-red rounded-full transition duration-500 ease-in-out hover:bg-accent-blue text-default-text "
    >
      {text}
    </Link>
  );
};

export default CTABtn;
