import React from "react";
import Footer from "../components/footer";
import AppNav from "../components/appNav";
import HeroKitGigital from "../components/heroKitDigital";
import BannerSection from "../components/bannerSection";
import KitDigitalInfoSection from "../components/kitDigitalnfoSection";
import SolutionsSection from "../components/solutionsSection";
import SegmentsSection from "../components/segmentsSection";
import { useTranslation } from "react-i18next";

const KitDigitalPage = () => {
  const {t} = useTranslation();

  return (
    <>
      <div className="flex flex-col kode-bg kit-digital-bg">
        <AppNav textColor="text-black-text" />
        <div className="lg:py-32 py-20">
          <HeroKitGigital
            title={t("Digital Kit Program")}
            text={t("¡Transforma tu Negocio Hoy!")}
          />
        </div>
      </div>
      <BannerSection />
      <KitDigitalInfoSection />

      <SolutionsSection/>
      <SegmentsSection/>

      <BannerSection />
      <div className="container py-5 px-4 md:px-10">
      <p className="text-sky-800 text-center text-ld md:text-xl font-thin">{t("Proyecto desarrollado en el marco de los fondos europeos 'Next Generation EU' dentro del Plan de Recuperación Transformación y Resiliencia")}</p>
      </div>
     
      <Footer />
    </>
  );
};

export default KitDigitalPage;
