export const navData = [
  {
    path: "/services",
    text: "Services",
  },
  {
    path: "/team",
    text: "Meet the Team",
  },
  {
    path: "/our-footprint",
    text: "Our Footprint",
  },
  {
    path: "/get-in-touch",
    text: "Get in Touch",
  },
  {
    path: "/kit-digital",
    text: "Kit Digital",
  },
];
