import mapPoint from "./../../assets/img/map-point.png";
import emailIcon from "./../../assets/img/email-section-contacts.png";
import telIcon from "./../../assets/img/tel-white.png";

export const contacts = [
  {
    cardIcon: mapPoint,
    title: "Address",
    text: "Calle el Cardon, 2 loc 14 38660 Madronal de Adeje",
    lowerText: "Santa Cruz de Tenerife, Islas Canarias Tenerife Spain",
  },
  {
    cardIcon: emailIcon,
    title: "Email address",
    text: "Hola@Kode.Zone",
    lowerText: "Monday to Friday 8am - 6pm",
  },
  {
    cardIcon: telIcon,
    title: "Contact Info",
    text: "+34 691 38 13 26",
    lowerText: "Monday to Friday 8am - 6pm",
  },
];
