import React from "react";
import { ContactCardProps } from "../types/appTypes";

const ContactCard: React.FC<ContactCardProps> = ({
  cardIcon,
  title,
  text,
  lowerText,
}) => {
  return (
    <ul className="flex flex-col justify-center items-center max-w-md text-default-text text-center">
      <li className="mb-5">
        <img className="h-[50px]" src={cardIcon} alt="icon" />
      </li>
      <li className="md:text-3xl text-2xl mb-3">{title}</li>
      <li className="text-xs mb-1">{text}</li>
      <li className="text-xs">{lowerText}</li>
    </ul>
  );
};

export default ContactCard;
